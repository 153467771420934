.container{
margin: 0 0 0 25px;
}
.hero{
    display: flex;
    overflow: hidden;
}
.hero-left{
    width: 50%;
    margin-top: 100px;
    top: 0;
}
.hero-left .one{
    font-size: 16px;
    letter-spacing: 10px;
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    color: rgb(113, 113, 113);
}
.two{
    font-size: 75px;
    font-weight: bold;
    text-transform: capitalize;
    font-family: 'PT Serif', serif;
}
.three{
    font-size: 30px;
    font-weight: bold;
    text-transform: capitalize;
    font-family: 'Lora', serif;
}
.four{
    font-size: 16px;
    text-transform: capitalize;
    width: 75%;
    font-family: 'Raleway', sans-serif;
}
.hero-right{
    width: 60%;
    position: relative;
}
.right-card{
   width: 1100px;
   height:auto;
   left: 0;
   align-items: flex-start;

   /* border: 1px solid red;   */
}
.hero-right img{
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
    cursor: pointer;
    z-index: 100;

}
.hero-left button{
    padding: 10px 20px 10px 20px;
    margin-top: 20px;
    border: none;
    outline: none;
    background: #000;
    color: #fff;
    font-weight: lighter;
    text-transform: capitalize;
    z-index: 100;
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
}


@media screen and (max-width:850px){
    .container{
        margin: 0 0 0 0px;
        text-align: center;
        }
    .hero{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }
    .four{
        width: 75%;
        text-align: center;
    }
    .hero-left{
        width:100%;
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .hero-right{
        width: 100%;
       
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .right-card{
        width:500px;
   
     }
     .hero-right img{
         width: 100%;
         height: 100%;
         padding-bottom:0px;
         z-index: 100;
     }
     .two{
        font-size: 50px;
    }
    .three{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .four{
        font-size: 16px;
    }
}


@media screen and (max-width:540px){
    .container{
        margin: 0 0 0 0px;
        text-align: center;
        }
    .hero{
        display: flex;
        flex-direction: column-reverse;
    }
    .four{
        width: 100%;
    }
    .hero-left{
        width:100%;
        margin-top: 0px;
    }
    .hero-right{
        width: 100%;
    }
    .right-card{
        width:400px;
        /* border: 1px solid red;   */
     }
     .hero-right img{
         width: 100%;
         height: 100%;
         padding-bottom:0px;
         z-index: 100;
     }
     .two{
        font-size: 50px;
    }
    .three{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .four{
        font-size: 16px;
    }
}