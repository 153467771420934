.service-container{
    margin: 100px 50px 0px 50px;
}
.service{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.service-one{
    margin-bottom: 50px;
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    color: rgb(113, 113, 113);
    font-size: 18px;
    letter-spacing: 10px;
}
.service-two{
    font-size: 50px;
    font-weight: bold;
    text-transform: capitalize;
    font-family: 'Lora', serif;
    margin-bottom: 50px;
}
.service-three{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 50px;
    position: relative;
    overflow: hidden;
}
.service-three .fashion{
    width: calc(100/3);
    text-align: center;
    align-content: center;

}
.fashion img{
    margin-bottom: 20px;
}
.fashion h5{
    margin-bottom: 20px;
    font-size: 35px;
    font-family: 'Raleway', sans-serif;
}
.fashion p{
    font-size: 16px;
    color: rgb(113, 113, 113);
    font-family: 'Raleway', sans-serif;
    line-height: 25px;
}
.service-four{
    margin-top: 50px;
}
.service-four button{
    padding: 10px 20px 10px 20px;
    margin-top: 20px;
    border: none;
    outline: none;
    background: rgb(191, 165, 18);
    color: #fff;
    font-weight: lighter;
    text-transform: capitalize;
    cursor: pointer;
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
}
.s-img-left{
    position: absolute;
    left: -100px;
    z-index: -1;
}
.s-img-left img{
    width: 150px;
}
.s-img-right{
    position: absolute;
    right: -150px;
    z-index: -1;
    rotate:180deg;
}
.s-img-right img{
    width: 200px;
}


@media screen and (max-width:850px){
    .service-one{
        margin-bottom: 35px;
    }
    .service-two{
        font-size: 35px;
        font-weight: bold;
    }
    .service-three{
        grid-template-columns: 1fr;
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
    }
    .service-container{
        margin: 100px 25px 0px 25px;
       
    }
    .fashion{
        width: 75%;
    }
}
@media screen and (max-width:540px){
    .service-one{
        margin-bottom: 35px;
    }
    .service-two{
        font-size: 35px;
        font-weight: bold;
    }
    .service-three{
        grid-template-columns: 1fr;
    }
    .service-container{
        margin: 100px 25px 0px 25px;
    }
}