 @import url('https://fonts.googleapis.com/css2?family=Lora:wght@500;700&family=PT+Serif:wght@400;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,200&display=swap');



*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Poppins', sans-serif;
  font-family: 'Raleway', sans-serif; 

  font-family: 'Lora', serif;
 font-family: 'Poppins', sans-serif;
  font-family: 'PT Serif', serif;
 */
}
body{
  background: rgb(240, 240, 240);
}
