.navigation{
    margin: 25px 25px 0px 25px;
}
.nav{
    width: 100%;
    height:50px;
    /* border: 1px solid red; */
}
.nav-item{
    display: flex;
    align-items:center;
    justify-content: space-between;
    position: relative;
}
.nav-item .logo img{
    width: 75px;
    cursor: pointer
}
.nav-item .menu {
    width: 20px;
    cursor: pointer;
    z-index: 1000;
}
.nav-item .menu.active{
    rotate: 45deg;
}
.menu-item{
    top:50px;
    height:auto;
    width:100%;
    height: auto;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background: rgb(230, 196, 28);
    position: absolute;
    overflow: hidden;
    opacity: 0;
    transition: 0.5s ease-in-out;
}
.menu-item.active{
    opacity: 1;
    transition: 0.5s ease-in-out;
    z-index: 100;
}
.menu-item ul{
    list-style: none;
    text-decoration: none;
    text-align: center;
}
.menu-item ul li{
    margin-top: 25px;
    margin-bottom: 50px;
    font-size: 50px;
    text-transform:uppercase;
    font-weight: bold;
    cursor: pointer;
    padding: 15px;
}
.menu-item ul li:hover{
    color: #fff;
}
.menu-item .img-one{
    position: absolute;
    bottom: -100px;
    left: 100px;
}
.menu-item .img-two{
    position: absolute;
    bottom: -100px;
    right: 100px;
}
.menu-item img{
    width: 250px;
}

@media screen and (max-width:640px) {
    .menu-item img{
        width: 100px;
    }
    .menu-item .img-one{
        position:absolute;
        bottom:0px;
        left:0px;
    }
    .menu-item .img-two{
        position: absolute;
        bottom:0px;
        right:0px;
    }

}