.shop{
    margin: 100px 50px 0px 50px;
    text-decoration: none;
}
.shop-body{
    width: 100%;
    height: 100%;
    text-align: center;
}
.shop-one{
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    color: rgb(113, 113, 113);
    font-size: 18px;
    letter-spacing: 10px;
    margin-bottom: 25px;
}
.shop-two{
    font-size: 50px;
    font-weight: bold;
    text-transform: capitalize;
    font-family: 'Lora', serif;
    margin-bottom: 50px;
}
.shop-three{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 25px;
    align-content: center;
    justify-content: center;
}
.shop-cards{
    width: auto;
    height: 500px;
    position: relative;
    border-radius: 10px;
    cursor: pointer;
}
.shop-cards:hover .shop-overlays{
    opacity: 1;
    transition: 0.5s ease-in;
}

.shop-overlays{
    position: absolute;
    bottom: 0;
    background: rgb(255, 212, 55);
    width: 100%;
    height: 50%;
    border-radius:0px 0px 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0;
    color: #000;
    opacity: 0;
}
.shop-overlays h5{
    font-size: 25px;
    font-weight: normal;
    text-transform: capitalize;
    font-family: 'Lora', serif;
    margin-bottom: 10px;
}
.shop-overlays h6{
    font-size: 20px;
    font-weight: normal;
    text-transform: capitalize;
    font-family: 'Lora', serif;
    margin-bottom: 10px;
}
.shop-overlays span{
    width:50px;
    height: 50px;
    cursor: pointer;
}
.shop-cards img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius:10px;
}
.shop-four{
    margin-top: 50px;
}
.shop-four button{
    padding: 10px 20px 10px 20px;
    margin-top: 20px;
    border: none;
    outline: none;
    background: rgb(191, 165, 18);
    color: #fff;
    font-weight: lighter;
    text-transform: capitalize;
    cursor: pointer;
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
}

@media screen and (max-width:850px){
    .shop-three{
        grid-template-columns: 1fr;
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .shop-cards{
        width:100%;
    }
}
@media screen and (max-width:540px){
    .shop{
        margin: 100px 25px 0px 25px;
    }
    .shop-two{
        font-size: 35px;
    }
    .shop-three{
        grid-template-columns: 1fr;
    }
    .shop-cards{
        width: auto;
        height: 350px;
        position: relative;
        border-radius: 10px;
        cursor: pointer;
    }
}